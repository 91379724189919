<script setup lang="ts">
interface Props {
  medium?: boolean;
  section?: boolean;
  text?: boolean;
  rightIcon?: boolean;
  leftIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  medium: false,
  section: false,
  text: false,
  rightIcon: false,
  leftIcon: false
});

const buttonClasses = computed(() => ({
  'v-btn--medium': props.medium,
  'v-btn--section': props.section,
  'v-btn--text': props.text,
  'v-btn--right-icon': props.rightIcon,
  'v-btn--left-icon': props.leftIcon
}));

</script>

<template>
  <button v-bind="$attrs" class="v-btn" :class="buttonClasses">
    <slot />
  </button>
</template>

<style lang="scss">
.v-btn {
  padding: 16px 30px;
  color: $color-blue-3;
  display: flex;
  border-radius: 10px;
  box-shadow: $box-shadow-3;
  background: $color-blue-2;
  white-space: nowrap;
  &--medium{
    @include font-style-500-18-140;
    color: $color-blue-16;
  }

  &--section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blue-12;
    box-shadow: $box-shadow-13;
    border-radius: 50px;
    padding: 10px;
    outline: none;
  }

  &--text {
    background-color: transparent;
    box-shadow: none;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
  }

  &--left-icon {
    .v-icon {
      margin-right: 4px;
    }
  }

  &--right-icon {
    .v-icon {
      margin-left: 4px;
    }
  }
}
</style>
